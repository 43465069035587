import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["userListItem", "inputFile", "fileList", "fileName", "formWrap", "textarea", "emptyView", "dialog", "error", "chatActions"];

  connect() {
    document.addEventListener('parent:frame-render', this.handleParentFrameLoaded);
    this.dt;
  }

  handleParentFrameLoaded = () => {
    const _this = this;
    if(document.getElementById("chat-messages").src){
    let currentRoomUrl = document.getElementById("chat-messages").src;
    const currentRoomPath = new URL(currentRoomUrl).pathname; 
    const extractedRoomPath = currentRoomPath.replace(/\/$/, ''); 
    _this.userListItemTargets.forEach(userItem => {
      let userItemHref = userItem.href;
      const userItemPath = new URL(userItemHref).pathname;
      const extractedUserPath = userItemPath.replace(/\/$/, '');
      if (extractedUserPath === extractedRoomPath) {
        setTimeout(()=>{
        _this.toggleActiveClass(userItem.closest(".user-item"));
      }, "60");
      }
      
    });
  }
  };

  showMessages() {
    if (window.innerWidth < 768) {
      setTimeout(()=>{
      this.element.classList.add("-translate-x-[100vw]");
    }, "60");
    }
    else {
      return false;
    }
  }

toggleChatActions(e) {
    e.stopPropagation();
    const chatActions = this.chatActionsTarget;
    if (chatActions.classList.contains('opacity-0')) {
        chatActions.classList.remove('opacity-0', 'invisible');
        chatActions.classList.add('opacity-100', 'visible');
    } else {
        chatActions.classList.remove('opacity-100', 'visible');
        chatActions.classList.add('opacity-0', 'invisible');
    }
}


  hideMessages() {
    if (window.innerWidth < 768) {
      this.element.classList.remove("-translate-x-[100vw]");
    }
    else {
      return false;
    }
  }
  clearStyles() {
    if (window.innerWidth > 768 && this.element.classList.contains("-translate-x-[100vw]")) {
      this.element.classList.remove("-translate-x-[100vw]");
    }
  }

  toggleActiveClass(event){
    this.userListItemTargets.forEach((item) => {
      const listItem = item.closest(".user-item");
      listItem.classList.remove("bg-downy-100");
    });
    event.classList.add("bg-downy-100");
  }  

  handleFileChange() {
    this.dt = new DataTransfer();
    this.formWrapTarget.classList.remove("h-[180px]");
    const files = this.inputFileTarget.files;
    const filesList = this.fileListTarget;
    filesList.innerHTML = '';

    if (files.length > 3) {
      this.fileListTarget.innerHTML += `<div class="text-xs font-medium text-red-600" data-chat-target="error">Too many attachments. Max 3 allowed.</div>`;
      this.inputFileTarget.value = '';
    }
    else {
      for (const file of this.inputFileTarget.files) {        
        const fileBlock = document.createElement("span");
        fileBlock.classList.add("list-item-wrap");
  
        fileBlock.innerHTML =  `
        <div class="wrap flex justify-start w-[calc(100%-32px)] gap-2">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex-grow-0 flex-shrink-0 w-7 h-7 relative"
                  preserveAspectRatio="xMidYMid meet">
                <rect width="32" height="32" rx="16" fill="#F3FAFA"></rect>
                <path d="M16.6667 9.33325H12C11.6464 9.33325 11.3073 9.47373 11.0572 9.72378C10.8072 9.97382 10.6667 10.313 10.6667 10.6666V21.3333C10.6667 21.6869 10.8072 22.026 11.0572 22.2761C11.3073 22.5261 11.6464 22.6666 12 22.6666H20C20.3536 22.6666 20.6928 22.5261 20.9428 22.2761C21.1929 22.026 21.3334 21.6869 21.3334 21.3333V13.9999M16.6667 9.33325L21.3334 13.9999M16.6667 9.33325V13.9999H21.3334"
                      stroke="#62C6C5" stroke-width="1.33333" stroke-linecap="round"
                      stroke-linejoin="round"></path>
            </svg>
            <div class="overflow-hidden">
                <div class="font-medium whitespace-nowrap truncate file-name">${file.name}</div>
                <div class="text-gray-500">${(file.size / 1048576).toFixed(2) + 'MB'}</div>
            </div>
        </div>`;
  
        const deleteButton = document.createElement("span");
        deleteButton.classList.add("file-delete");
        deleteButton.innerHTML = `
                                  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                    <path d="M11 4.5V3.9C11 3.05992 11 2.63988 10.8365 2.31901C10.6927 2.03677 10.4632 1.8073 10.181 1.66349C9.86012 1.5 9.44008 1.5 8.6 1.5H7.4C6.55992 1.5 6.13988 1.5 5.81901 1.66349C5.53677 1.8073 5.3073 2.03677 5.16349 2.31901C5 2.63988 5 3.05992 5 3.9V4.5M6.5 8.625V12.375M9.5 8.625V12.375M1.25 4.5H14.75M13.25 4.5V12.9C13.25 14.1601 13.25 14.7902 13.0048 15.2715C12.789 15.6948 12.4448 16.039 12.0215 16.2548C11.5402 16.5 10.9101 16.5 9.65 16.5H6.35C5.08988 16.5 4.45982 16.5 3.97852 16.2548C3.55516 16.039 3.21095 15.6948 2.99524 15.2715C2.75 14.7902 2.75 14.1601 2.75 12.9V4.5" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </svg>`;

        deleteButton.addEventListener("click", (event) => this.deleteFile(event));
  
        fileBlock.appendChild(deleteButton);
        filesList.appendChild(fileBlock);
  
        this.dt.items.add(file);
      }
  
      this.inputFileTarget.files = this.dt.files;      
    }
  }

  deleteFile(event) {
    const name = event.currentTarget.previousElementSibling.querySelector('.file-name').textContent;
    event.currentTarget.parentElement.remove();

    for (let i = 0; i < this.dt.items.length; i++) {
      if (name === this.dt.items[i].getAsFile().name) {
        this.dt.items.remove(i);
        break;
      }
    }

    this.inputFileTarget.files = this.dt.files;
    console.log(this.inputFileTarget.files);
    
  }

  resetStyle() {
    if (!this.formWrapTarget.classList.contains("h-[180px]")) {
      this.formWrapTarget.classList.add("h-[180px]");
    }
  }

  validateTextarea(event) {
    if (this.textareaTarget.value.trim() === '')
    {
      event.preventDefault();

      this.formWrapTarget.classList.remove("h-[180px]");

      let parentElement = this.fileListTarget.parentNode;
      let errorMessageDiv = document.createElement('div');

      errorMessageDiv.className = 'w-full text-xs font-medium text-red-600 order-1';
      errorMessageDiv.textContent = 'Message cannot be empty';
      parentElement.prepend(errorMessageDiv);
    }
    else {
      this.resetStyle();      
      setTimeout(()=>{
        this.dt.clearData();
      }, "50");  
    }
  }
  
}